import React, { useEffect } from "react"

import PDF from "../pdf/Presentation-coaching-SLAP-digital.pdf"
import Layout from "../components/layout"
import Button from "../components/elements/Button"
import Section from "../components/elements/Section"

const OffreCoaching = ({ location }) => {
  const onDownload = () => window.open(PDF)

  useEffect(() => {
    onDownload()
  })

  return (
    <Layout
      location={location.pathname}
      crumbLabel="Offre coaching"
      locationGTM={location.href}
    >
      <Section padding="100px" id="expertises">
        <p>
          Veuillez cliquer sur le bouton si le téléchargement ne se lance pas
        </p>
        <Button onClick={onDownload}>Télécharger le document</Button>
      </Section>
    </Layout>
  )
}

export default OffreCoaching
